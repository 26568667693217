
































































































import { Component, Prop } from "vue-property-decorator";
import UIkit from "uikit";
import Valance from "@/components/designer/sidebar/canopy/Valance.vue";
import * as option from "@/models/configurator/configurator_options";
import { mixins } from "vue-class-component";
import { CanopyStore } from "@/mixins/store";
import Standard from "../../../../assets/images/valance-standard.svg?inline";
import Trojan from "../../../../assets/images/valance-trojan.svg?inline";
import WhaleTail from "../../../../assets/images/valance-whale-tail.svg?inline";
import Scallop from "../../../../assets/images/valance-scallop.svg?inline";
import { StockMixin } from "@/mixins/stock";
import ValanceDisclaimer from "@/components/designer/reusable/ValanceDisclaimer.vue";
@Component({
  components: {
    Valance,
    Standard,
    Trojan,
    WhaleTail,
    Scallop,
    ValanceDisclaimer,
  },
})
export default class ValanceGroup extends mixins(CanopyStore, StockMixin) {
  protected selected: option.Valance | null = null;
  protected option = option;

  protected toggleValanceModal(): void {
    UIkit.modal(document.getElementById("valance-modal") as HTMLElement).show();
  }

  created() {
    this.readStoreData();
  }

  protected readStoreData(): void {
    if (this.valance) {
      this.selected = this.valance;
    } else {
      this.selected = option.Valance.Standard;
    }
  }

  protected async select(valance: option.Valance): Promise<void> {
    this.selected = valance;
    await this.$viewer.ChangeValance(valance);
    this.addValance(valance);
  }
}
