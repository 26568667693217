import { Component, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import * as option from "@/models/configurator/configurator_options";
import { FabricSwatch } from "@/models/products/fabric";
import { LayoutStore, ShapeStore, CanopyStore } from "@/mixins/store";
import FabricService from "@/services/fabric_service";
@Component
export class CanopyRules extends mixins(LayoutStore, ShapeStore, CanopyStore) {
  protected selectedMainCanopy: option.Panel | null = null;
  protected selectedVentMiddle: option.Panel | null = null;
  protected selectedRib: option.Rib | null = null;
  //protected lastRibFabric: FabricSwatch | null = null;
  protected lastBindingFabric: FabricSwatch | null = null;
  protected fabricService = new FabricService();
  protected whiteFabric = {} as FabricSwatch;
  // get ribFabricSwatch(): FabricSwatch | null {
  //   return this.ribFabric ? this.ribFabric : this.lastRibFabric;
  // }

  async created() {
    const mainFabricWeight = this.mainFabric[0].weight;
    let whiteFabricMfrCode = '';
    switch (mainFabricWeight) {
      case ('8') :
        whiteFabricMfrCode = '57003';
        break;
      case ('9') :
        whiteFabricMfrCode = 'R-099';
        break;
    }
    const res = await this.fabricService.getSingleFabric(whiteFabricMfrCode);
    this.whiteFabric = res;
  }

  get bindingFabricSwatch(): FabricSwatch | null {
    return this.bindingFabric ? this.bindingFabric : this.lastBindingFabric;
  }

  protected async removeBinding(): Promise<void> {
    if (this.bindingFabric) {
      this.lastBindingFabric = this.bindingFabric;
    }
    this.addBindingFabric(null);
    await this.$viewer.ChangeBindingFabric(null);
  }

  @Watch("selectedMainCanopy")
  protected async selectMainCanopy(): Promise<void> {
    if (this.selectedMainCanopy) {
      this.addMainCanopy(this.selectedMainCanopy);
      if (
        this.selectedMainCanopy === option.Panel.Solid &&
        this.mainFabric[1]
      ) {
        this.addMainFabric([this.mainFabric[0]]);
        await this.$viewer.ChangeCanopyFabric(
          this.mainFabric[0].fabric_scale,
          this.mainFabric[0].mfr_code
        );
      }
      if (
        this.selectedMainCanopy === option.Panel.Solid &&
        this.valanceFabric[1]
      ) {
        this.addValanceFabric([this.valanceFabric[0]]);
        await this.$viewer.ChangeValanceFabric(
          this.valanceFabric[0].fabric_scale,
          this.valanceFabric[0].mfr_code
        );
      }
    }
  }

  @Watch("selectedVentMiddle")
  protected async selectVentMiddle(): Promise<void> {
    if (this.selectedVentMiddle) {
      this.addVentMiddle(this.selectedVentMiddle);
    }
    if (
      this.selectedVentMiddle === option.Panel.Solid &&
      this.ventMiddleFabric[1]
    ) {
      this.addVentMiddleFabric([this.ventMiddleFabric[0]]);
      await this.$viewer.ChangeDblVentFabric(
        this.ventMiddleFabric[0].fabric_scale,
        this.ventMiddleFabric[0].mfr_code
      );
    }
  }

  @Watch("selectedRib")
  protected async selectRib(): Promise<void> {
    if (this.selectedRib) {
      const fabric = this.selectedRib === option.Rib.Grommets ? undefined : this.mainFabric[0].mfr_code
      const swatch = this.selectedRib === option.Rib.Grommets ? null : this.mainFabric[0];
      this.$viewer.ChangeRibAttachment(this.selectedRib, fabric)
      this.addRib(this.selectedRib);
      this.addRibFabric(swatch)
    }
  }
}
