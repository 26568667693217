




































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Swatch extends Vue {
  @Prop() img!: string | null;
  @Prop() discontinued!: boolean | null;
  get cdnUrl(): string {
    return process.env.VUE_APP_CDN_URL + "/fabric_swatches/";
  }
}
