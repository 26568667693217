import { Component, Prop, Watch } from "vue-property-decorator";
import UIkit from "uikit";
import * as option from "@/models/configurator/configurator_options";
import { FabricComponent } from "@/models/configurator/fabric_components";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore, ShapeStore } from "@/mixins/store";
import { ProductLineName } from "@/models/products/collection";
import { CanopyRules } from "@/mixins/business_logic/canopy/canopy_rules";
import { ribAttachment } from '@/mixins/business_logic/global/rib_rules';

@Component
export default class CanopyLayouts extends mixins(
  CanopyStore,
  ShapeStore,
  LayoutStore,
  CanopyRules,
) {
  @Prop({ default: false }) isStockOnly!: boolean;
  @Prop() hasVent!: boolean;
  @Prop() hasValance!: boolean;
  protected option = option;
  protected fabricComponent = FabricComponent;
  protected component: FabricComponent | null = null;
  protected Preset = option.Preset;
  protected ProductLineName = ProductLineName;
  protected ribAttachment = ribAttachment;

  @Watch("isStockOnly")
  protected turnOffAlternatingPanel(): void {
    if (this.isStockOnly) {
      this.selectedMainCanopy = option.Panel.Solid;
      this.selectedVentMiddle = option.Panel.Solid;
      if (this.collection.handle === ProductLineName.Greenwich || this.collection.handle === ProductLineName.Eclipse) {
        this.selectedRib = option.Rib.Grommets;
      } else if (ribAttachment.pockets.includes(this.collection.handle)) {
        this.selectedRib = option.Rib.Pockets;
      } else {
        this.selectedRib = null;
      }
    }
  }

  protected get isDoubleVent(): boolean {
    let dv = false;
    if (
      this.preset === option.Preset.S1DV ||
      this.preset === option.Preset.D5
    ) {
      dv = true;
    }
    return dv;
  }

  protected showFabricSelector(selection: FabricComponent): void {
    this.component = selection;
    const el = document.getElementById("fabric-selector") as HTMLElement;
    UIkit.offcanvas(el).show();
  }

  protected toggleBindingsModal(): void {
    UIkit.modal(
      document.getElementById("bindings-modal") as HTMLElement
    ).show();
  }

  protected toggleRibsModal(): void {
    UIkit.modal(document.getElementById("ribs-modal") as HTMLElement).show();
  }

  protected toggleVentsModal(): void {
    UIkit.modal(document.getElementById("vents-modal") as HTMLElement).show();
  }
}
