














import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { CanopyStore } from "@/mixins/store";
import { EventBus } from "@/event-bus";
import { FabricMixin } from "@/mixins/fabric";
/** Logic to render this component should be in parent */
@Component
export default class FlipValancePanels extends mixins(
  CanopyStore,
  FabricMixin
) {
  protected hover = false;

  protected async flipValance(): Promise<void> {
    const swatch = this.valanceFabric[0];
    const swatchAlt = this.valanceFabric[1];
    this.addValanceFabric([swatchAlt, swatch]);
    this.allowValance(true);
    await this.$viewer.ChangeValanceFabric(
      swatchAlt.fabric_scale,
      swatchAlt.mfr_code,
      swatch.fabric_scale,
      swatch.mfr_code
    );
  }

  get img(): string {
    let img;
    if (this.hover) {
      img = require("../../../../assets/images/valance-switch-hover.png");
    } else {
      img = require("../../../../assets/images/valance-switch.png");
    }
    return img;
  }

  get className(): string {
    let className;
    if (this.hover) {
      className = "hover";
    } else {
      className = "no-hover";
    }
    return className;
  }
}
