























import { Component, Prop } from "vue-property-decorator";
import * as option from "@/models/configurator/configurator_options";
import { mixins } from "vue-class-component";
import { CanopyStore, LayoutStore } from "@/mixins/store";
import Standard from "@/components/designer/sidebar/canopy/layouts/Standard.vue";
import D1 from "@/components/designer/sidebar/canopy/layouts/D1.vue";
import D2D3 from "@/components/designer/sidebar/canopy/layouts/D2D3.vue";
import D4 from "@/components/designer/sidebar/canopy/layouts/D4.vue";
import D5 from "@/components/designer/sidebar/canopy/layouts/D5.vue";
import { StockMixin } from "@/mixins/stock";

@Component({
  components: {
    Standard,
    D1,
    D2D3,
    D4,
    D5
  }
})
export default class CanopySidebar extends mixins(CanopyStore, LayoutStore, StockMixin) {
  @Prop() hasVent!: boolean;
  @Prop() hasValance!: boolean;
  protected Preset = option.Preset;
}
